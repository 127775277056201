import React from 'react';
import "./page.css";
import "./contact.css";
import { NavBar } from '../widgets/navbar/navbar';
import { Footer } from '../widgets/footer/footer';
import { createEmailClickHref, getEmail } from '../hooks/email';
import ContactGoogleForm from '../widgets/email/contactGoogleForm';
import { getPhoneNumberAsTel, getPhoneNumberAsString } from '../hooks/phone';

interface ContactProps { }

export function Contact(props: ContactProps) {
  return (
    <div className="page contact">
      <NavBar activeButton={'contact'} />
      <div className='content'>
        <h2 className='content-header'>
          Contact
        </h2>
        <div className='content-text-container'>
          <div className='contact-container'>
            <div className='contact-info'>
              <p className='bold'>Want to get in touch?</p>
              <span>Fill out this form!</span>

              <p className='bold'>Form not working?</p>
              <span>Email us at</span>
              <a href={createEmailClickHref()}>{getEmail()}</a>

              <span className='contact-info-options'>Or text/call at</span>
              <a href={getPhoneNumberAsTel()}>{getPhoneNumberAsString()}</a>
            </div>
            <div className='contact-form'>
              <ContactGoogleForm />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}