import React from 'react';
import "./page.css";
import "./schedule.css";
import { NavBar } from '../widgets/navbar/navbar';
import { Footer } from '../widgets/footer/footer';
import { CalendarDay } from '../widgets/calendar/calendarDay';
import { TimeSlotData } from '../hooks/timeSlot';

interface ScheduleProps { }

const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const dailySchedule = {
  'Monday': [
    { startTime: '6:00am', endTime: '9:00am' },
    { startTime: '4:00pm', endTime: '6:00pm' },
  ],
  'Tuesday': [
    { startTime: '06:00am', endTime: '6:00pm' },
  ],
  'Wednesday': [
    { startTime: '06:00am', endTime: '6:00pm' },
  ],
  'Thursday': [
    { startTime: '6:00am', endTime: '9:00am' },
    { startTime: '4:00pm', endTime: '6:00pm' },
  ],
  'Friday': [
    { startTime: '6:00am', endTime: '9:00am' },
    { startTime: '4:00pm', endTime: '6:00pm' },
  ],
  'Saturday': [
    { startTime: '04:00pm', endTime: '6:00pm' },
  ],
  'Sunday': [],
} as { [key: string]: TimeSlotData[] };

export function Schedule(props: ScheduleProps) {
  return (
    <div className="page schedule">
      <NavBar activeButton={'schedule'} />
      <div className='content'>
        <h2 className='content-header'>
          Schedule
        </h2>
        <div className='content-text-container'>
          <div className="calendar">
            {daysOfTheWeek.map((day) => (
              <CalendarDay key={day} dayOfWeek={day} timeSlots={dailySchedule[day]} />
            ))}
          </div>

          <div className='schedule-text'>Interested in signing up for a time slot? Click the time to jump straight to the contact page!</div>
        </div>
      </div>
      <Footer />
    </div >
  );
}