import React, { useState } from 'react';
import "./navbar.css";
import { Scrim } from '../scrim/scrim';
import { NavbarButton } from '../button/button';
import { GrowingLogo } from '../logo/logo';
// import gym_logo_gold from '../../img/gym_logo_gold.jpg'; // Tell webpack this JS file uses this image
import { useNavigate } from 'react-router-dom';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface NavBarProps {
  activeButton: string;
}

export function NavBar(props: NavBarProps) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="navbar-container">
      <Scrim backgroundColor="black" height="100%" width="100%" blur={true} classes="navbar-scrim" />
      <div className="navbar-contents">
        <GrowingLogo onClick={() => navigate('/')} classes={'navbar-growing-logo'} />
        <NavBarButtons activeButton={props.activeButton} />
        <div className="menu-toggle" onClick={handleMenuToggle}>
          <FontAwesomeIcon icon={showMenu ? faClose : faBars} />
          <NavBarButtons activeButton={props.activeButton} isMenuOpen={showMenu} />
        </div>
      </div >
    </div >
  );
};

interface NavBarButtonsProps {
  activeButton: string;
  isMenuOpen?: boolean;
}

export function NavBarButtons(props: NavBarButtonsProps) {
  const navigate = useNavigate();

  return (
    <div className={"navbar-buttons" + (props.isMenuOpen ? ' menuIsOpen' : '')}>
      <NavbarButton onClick={() => navigate('/home')} text="home" activeButton={props.activeButton} />
      <NavbarButton onClick={() => navigate('/about')} text="about" activeButton={props.activeButton} />
      <NavbarButton onClick={() => navigate('/schedule')} text="schedule" activeButton={props.activeButton} />
      <NavbarButton onClick={() => navigate('/contact')} text="contact" activeButton={props.activeButton} />
    </div>
  )
}

// IDEA:
// Make a custom component for the GYM logo where it's the letters G.Y.M.
// * the periods are stacked over the letters a bit, just like in the logo
// * each period gets bigger
// * when you hover over a period, it grows!!
// OR
// the circle behind G.Y.M. grows on hover
// OR 
// the periods between the letters appear on hover