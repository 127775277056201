import React from 'react';
import "./logo.css";

interface GrowingLogoProps {
  onClick: () => void;
  classes?: string;
}

export function GrowingLogo(props: GrowingLogoProps) {
  return (
    <div className={'growing-logo-container' + (props.classes ? ' ' + props.classes : '')} onClick={props.onClick}>
      <GLetter />
      <Period classes='first-period' />
      <YLetter />
      <Period classes='second-period' />
      <MLetter />
      <Period classes='third-period' />
    </div>
  );
};

function GLetter() {
  return (
    <div className={'g-letter'}>G</div>
  )
}

function YLetter() {
  return (
    <div className={'y-letter'}>Y</div>
  )
}

function MLetter() {
  return (
    <div className={'m-letter'}>M</div>
  )
}

interface PeriodProps {
  classes?: string;
}

function Period(props: PeriodProps) {
  return (
    <div className={'circle' + (props.classes ? ' ' + props.classes : '')}></div>
  )
}