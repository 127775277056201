import React, { useState } from 'react';
import "./image.css";
import { Image } from './image';
import { ScrimOnHover } from '../scrim/scrim';

interface ZoomImageProps {
  src: string,
  altText?: string;
  classes?: string;
  scrimColor?: string;
  text?: string;
  button?: React.ReactNode;
  onClick?: () => void;
}

export function ZoomImage(props: ZoomImageProps) {
  const [isHovering, setIsHovering] = useState(false);

  const toggleHoverActions = () => {
    setIsHovering(!isHovering);
  };

  return (
    <div
      className={'zoom-image-container' + (props.classes ? ' ' + props.classes : '')}
      onMouseEnter={toggleHoverActions}
      onMouseLeave={toggleHoverActions}
      onClick={props.onClick ?? (() => { })}
    >
      {props.scrimColor ? <ScrimOnHover backgroundColor={props.scrimColor} isHovering={isHovering} height='300px' width='200px' /> : ''}
      {props.text || props.button ? <TextOverlay text={props.text} button={props.button} classes={isHovering ? 'hovering' : ''} /> : ''}
      <Image src={props.src} altText={props.altText} classes={'zoom-image' + (isHovering ? ' hovering' : '')} />
    </div>
  );
};

interface TextOverlayProps {
  text?: string;
  button?: React.ReactNode;
  classes?: string;
}

function TextOverlay(props: TextOverlayProps) {
  return (
    <div className={'text-overlay-container'}>
      <div className={'zoom-text' + (props.classes ? ' ' + props.classes : '')} style={{ opacity: props.text ? 1 : 0 }} dangerouslySetInnerHTML={{ __html: props.text ?? 'FILL' }} />
      <div className={'zoom-button' + (props.classes ? ' ' + props.classes : '')}>{props.button ?? ''}</div>
    </div>
  )
}