import React from 'react';
import "./image.css";

interface ImageProps {
  src: string,
  altText?: string;
  classes?: string;
  onClick?: () => void;
}

export function Image(props: ImageProps) {
  return (
    <div className='image-container' onClick={props.onClick ?? (() => { })}>
      <div style={{ backgroundImage: 'url(' + props.src + ')' }} className={'image' + (props.classes ? ' ' + props.classes : '')}></div>
    </ div >
  );
};