import React from 'react';
import "./calendar.css";
import { TimeSlotData } from '../../hooks/timeSlot';
import { createEmailWithRequestedDayAndTime } from '../../hooks/email';
import { SlidingButton } from '../button/button';

interface CalendarDayProps {
  dayOfWeek: string; // String representing the day of the week (e.g., "Monday")
  isSelected?: boolean; // Optional prop for marking a selected day
  className?: string; // Optional prop for custom CSS classes
  isDisabled?: boolean; // Optional prop to disable interaction with the day
  timeSlots?: TimeSlotData[]; // Array of time slots to be displayed for this day
}

export const CalendarDay: React.FC<CalendarDayProps> = ({
  dayOfWeek,
  isSelected,
  className,
  isDisabled,
  timeSlots,
}) => {
  function handleTimeSlotClick(dayOfWeek: string, timeSlot: TimeSlotData): (() => void) {
    return () => {
      if (!isDisabled) {
        createEmailWithRequestedDayAndTime(dayOfWeek, timeSlot);
      }
    };
  }

  const timeSlotElement = timeSlots && timeSlots.length ? timeSlots?.map((timeSlot) => (
    <TimeSlot key={timeSlot.startTime} {...timeSlot} onClick={handleTimeSlotClick(dayOfWeek, timeSlot)} />
  )) : <EmptyTimeSlot />;

  return (
    <div className={`calendar-day ${className || ''} ${isSelected ? 'selected' : ''}`}>
      <div className="day-header">
        {dayOfWeek}
      </div>
      <div className='time-slots'>
        {timeSlotElement}
      </div>
    </div>
  );
};

interface TimeSlotProps {
  startTime: string; // Time in 24-hour format (e.g., "09:00")
  endTime: string; // Time in 24-hour format (e.g., "17:00")
  onClick: () => void;
  className?: string; // Optional CSS class for styling the time slot
}

const TimeSlot: React.FC<TimeSlotProps> = ({ startTime, endTime, onClick, className }) => (
  // <div key={`${startTime}-${endTime}`} className={`time-slot ${className || ''}`} onClick={onClick}>
  //   {startTime} - {endTime}
  // </div>
  <SlidingButton classes={`time-slot ${className || ''}`} key={`${startTime} - ${endTime}`} text={`${startTime} - ${endTime}`} onClick={onClick} />
);

interface EmptyTimeSlotProps {
  className?: string; // Optional CSS class for styling the time slot
}

const EmptyTimeSlot: React.FC<EmptyTimeSlotProps> = ({ className }) => (
  <div className={`time-slot empty-time-slot ${className || ''}`}>
    CLOSED
  </div>
);