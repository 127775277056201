import React from 'react';
import "./page.css";
import { NavBar } from '../widgets/navbar/navbar';
import { ZoomImage } from '../widgets/image/zoomImage';
import { FullPageBackgroundImage } from '../widgets/image/fullPageBackgroundImage';
import hunter_sitting_at_machine from '../img/hunter_sitting_on_machine.jpg'; // Tell webpack this JS file uses this image
import { FillButton, SlidingButton } from '../widgets/button/button';
import { Footer } from '../widgets/footer/footer';

interface PlaygroundProps { }

export function Playground(props: PlaygroundProps) {
  function printButtonClicked() {
    console.log('button clicked');
  }

  function printImageClicked() {
    console.log('image clicked');
  }

  return (
    <div className="page home">
      <NavBar activeButton={'playground'} />
      <FullPageBackgroundImage altText='test' src='https://images.squarespace-cdn.com/content/v1/618af71063692a7515a66e9a/c94d0843-bd17-4442-8ea0-691929a877a8/5427CA6C-B0A2-4D41-AE01-6A63EA871645.jpeg' />
      <br></br>
      <br></br>
      <SlidingButton onClick={printButtonClicked} text="DISCOVER ALL CLASSES" />
      <br></br>
      <FillButton onClick={printButtonClicked} text="DISCOVER ALL CLASSES" />
      <div className="row">
        <ZoomImage src={hunter_sitting_at_machine} altText={'test'} scrimColor='blue' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
        <ZoomImage src={hunter_sitting_at_machine} altText={'test'} scrimColor='purple' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
        <ZoomImage src={hunter_sitting_at_machine} altText={'test'} scrimColor='pink' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
      </div>
      <div className="row">
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='orange' text="muay thai" onClick={printImageClicked} />
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='pink' button={<FillButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='green' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
      </div>
      <div className="row">
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='red' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
      </div>
      <div className="row">
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='orange' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
      </div>
      <div className="row">
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='yellow' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
      </div>
      <div className="row">
        <ZoomImage src={'https://assets-global.website-files.com/655f1bf932949f9dd0072eaf/65b0d0f9158698aa192e8ba5_course-thumb-mt-bw.webp'} altText={'test'} scrimColor='green' text="BJJ" button={<SlidingButton onClick={printButtonClicked} text="learn more" />} onClick={printImageClicked} />
      </div>
      <Footer />
    </div >
  );
}