import React from 'react';
import "./page.css";
import "./home.css";
import { NavBar } from '../widgets/navbar/navbar';
import { Footer } from '../widgets/footer/footer';
import hunter_headshot from '../img/hunter_headshot.jpg'; // Tell webpack this JS file uses this image
import hunter_pull_up from '../img/hunter_pull_up.jpg'; // Tell webpack this JS file uses this image
import bjj_group_pose from '../img/bjj_group_pose.jpg'; // Tell webpack this JS file uses this image
import { SlidingButton } from '../widgets/button/button';
import { useNavigate } from 'react-router-dom';
import { ZoomImage } from '../widgets/image/zoomImage';
import { createEmailWithFreeSessionRequest } from '../hooks/email';
import { navigateToHelenaBJJWebsite } from '../hooks/bjj';
import { THEME_GOLD } from '../utils/utils';

interface HomeProps { }

export function Home(props: HomeProps) {
  const navigate = useNavigate();

  return (
    <div className="page home">
      <NavBar activeButton={'home'} />
      <div className='content'>
        <h2 className='content-header'>
          Grow Your Muscles Gym
        </h2>
        <div className='content-text-container'>
          <div className='intro-section'>
            <div className="intro-text">
              <div>
                Growing your
              </div>
              <div>
                Muscles
              </div>
              <div>
                Mind
              </div>
              <div>
                and Mentality
              </div>
            </div>
            <div>
              <ZoomImage src={hunter_headshot} altText={'Hunter, GYM owner'} scrimColor={THEME_GOLD} button={<SlidingButton onClick={() => navigate('/about')} text="Learn about Hunter" />} onClick={() => { }} />
            </div>
          </div>
          <div className="column info-section">
            <div>
              Personal Growth
            </div>
            <div>
              Through Personal Training
            </div>
            <div>
              <SlidingButton onClick={createEmailWithFreeSessionRequest} text="TRY A FREE SESSION TODAY" />
            </div>
          </div>
          <div className="more-info-section">
            <ZoomImage src={hunter_pull_up} altText={'Personal training image'} scrimColor={THEME_GOLD} text="Personal Training" onClick={() => navigate('/schedule')} />
            <ZoomImage src={bjj_group_pose} altText={'Brazilian Jiu Jitsu image'} scrimColor={THEME_GOLD} text="Brazilian<br />Jiu Jitsu" onClick={navigateToHelenaBJJWebsite} />
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}