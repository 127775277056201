import React from 'react';
import "./button.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEnvelope, faCalendar, faPhone } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Image } from '../image/image';
import gym_logo_gold_removed_background from '../../img/gym_logo_gold-removebg-rectangle.png'; // Tell webpack this JS file uses this image
import { useNavigate } from 'react-router-dom';
import { getPhoneNumberAsTel } from '../../hooks/phone';
import { getInstagramLink, getFacebookLink } from '../../hooks/socialMedia'

interface ButtonProps {
  onClick: () => void,
  text: string,
  classes?: string,
  type?: "button" | "submit" | "reset" | undefined,
}

export function Button(props: ButtonProps) {
  return (
    <button className={"button" + (props.classes ? ' ' + props.classes : '')} onClick={props.onClick} type={props.type} >
      <span>{props.text}</span>
    </button>
  );
};

interface FillButtonProps {
  onClick: () => void,
  text: string,
  classes?: string,
  type?: "button" | "submit" | "reset" | undefined,
}

export function FillButton(props: FillButtonProps) {
  return (
    <Button onClick={props.onClick} text={props.text} classes={"fill-button" + (props.classes ? ' ' + props.classes : '')} type={props.type} />
  );
};

interface SlidingButtonProps {
  onClick: () => void,
  text: string,
  classes?: string,
  type?: "button" | "submit" | "reset" | undefined,
}

export function SlidingButton(props: SlidingButtonProps) {
  return (
    <FillButton onClick={props.onClick} text={props.text} classes={"sliding" + (props.classes ? ' ' + props.classes : '')} type={props.type} />
  );
};

interface NavbarButtonProps {
  onClick: () => void,
  text: string,
  activeButton: string;
}

export function NavbarButton(props: NavbarButtonProps) {
  return (
    <div className={"button navbar-button" + (props.text === props.activeButton ? ' active' : '')} onClick={props.onClick}>
      <div className="navbar-text">{props.text}</div>
      <div className="line"></div>
    </div>
  );
};

interface IconButtonProps {
  onClick: () => void,
  icon: IconProp,
}

function IconButton(props: IconButtonProps) {
  return (
    <div className="button icon-button fa-stack" onClick={props.onClick}>
      <FontAwesomeIcon icon={faCircle} className='background fa-stack-2x' />
      <FontAwesomeIcon icon={props.icon} className='icon fa-stack-1x' />
    </div>
  );
};

interface HrefIconButtonProps {
  href: string,
  icon: IconProp
}

function HrefIconButton(props: HrefIconButtonProps) {
  return (
    <div className="button icon-button fa-stack">
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faCircle} className='background fa-stack-2x' />
        <FontAwesomeIcon icon={props.icon} className='icon fa-stack-1x' />
      </a>
    </div>
  );
}

export function MailButton() {
  const navigate = useNavigate();
  return (<IconButton onClick={() => navigate('/contact')} icon={faEnvelope} />)

  // Alternative option: pop up the native email app
  // return (<HrefIconButton href={createEmailClickHref()} icon={faEnvelope} />);
}

export function FacebookButton() {
  return (<HrefIconButton href={getFacebookLink()} icon={faFacebookF as IconProp} />);
}

export function InstagramButton() {
  return (<HrefIconButton href={getInstagramLink()} icon={faInstagram as IconProp} />);
}

export function YouTubeButton() {
  return (<HrefIconButton href='www.youtube.com' icon={faYoutube as IconProp} />);
}

export function ScheduleButton() {
  const navigate = useNavigate();

  return (<IconButton onClick={() => navigate('/schedule')} icon={faCalendar} />)
}

export function PhoneButton() {
  return (<HrefIconButton href={getPhoneNumberAsTel()} icon={faPhone} />);
}

export function LogoButton() {
  const navigate = useNavigate();

  return (
    <div className="button icon-button fa-stack" onClick={() => navigate('/home')}>
      <FontAwesomeIcon icon={faCircle} className='background fa-stack-2x' />
      <Image src={gym_logo_gold_removed_background} classes='button logo-button' />
    </div>
  )
}