import React from 'react';
import "./page.css";
import "./about.css";
import { NavBar } from '../widgets/navbar/navbar';
import { FullPageBackgroundImage } from '../widgets/image/fullPageBackgroundImage';
import { Footer } from '../widgets/footer/footer';
import gym_building from '../img/gym_building.jpg'; // Tell webpack this JS file uses this image

interface AboutProps { }

export function About(props: AboutProps) {
  return (
    <div className="page about">
      <NavBar activeButton={'about'} />
      <FullPageBackgroundImage src={gym_building} scrimColor={"#000000"} />
      <div className='content'>
        <h2 className='content-header'>
          Our Mission
        </h2>
        <div className='content-text-container'>
          <div className="about-text">
            We all know working out is not easy and staying accountable is no easy task.
          </div>
          <div className="about-text">
            At Grow.Your.Muscles I believe that working out is a lifelong process of learning.
            </div>
          <div className="about-text">
            While the mission of Growing Your Muscles is very true; I urge people to try and Grow Their Minds by learning and improving in a comfortable setting.
            </div>
          <div className="about-text">
            Resistance training offers many health benefits and at Grow.Your.Muscles I strive to create functional programs for whatever you personally desire.
            </div>
          <div className="about-text">
            Anyone from combat sports competitors to avid backpackers, and anyone who dares to challenge themselves can achieve long lasting benefits.
            </div>
          <div className="about-text">
            We all have the choice to better ourselves, so will you choose Grow.Your.Muscles?
            </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}