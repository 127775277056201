import React from 'react';
import "./scrim.css";

interface ScrimProps {
  backgroundColor: string;
  height: string;
  width: string;
  blur?: boolean;
  classes?: string;
}

export function Scrim(props: ScrimProps) {
  return (
    <div className='scrim-container' style={{ height: props.height, width: props.width }}>
      {props.blur ?
        <div className='blur'
          style={{ height: props.height, width: props.width }}>
        </div> : ''}
      <div className={'scrim'
        + (props.classes ? ' ' + props.classes : '')}
        style={{ backgroundColor: props.backgroundColor, height: props.height, width: props.width }}>
      </div >
    </div >
  )
}

interface ScrimOnHoverProps {
  backgroundColor: string;
  height: string;
  width: string;
  isHovering?: boolean;
}

export function ScrimOnHover(props: ScrimOnHoverProps) {
  return (<Scrim backgroundColor={props.backgroundColor} height={props.height} width={props.width} classes={'scrim-on-hover' + (props.isHovering ? ' hovering' : '')} />)
}