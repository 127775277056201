import React from 'react';
import "./image.css";
import { Image } from './image';
import { Scrim } from '../scrim/scrim';

interface FullPageBackgroundImageProps {
  src: string,
  altText?: string;
  scrimColor?: string;
  classes?: string;
}

export function FullPageBackgroundImage(props: FullPageBackgroundImageProps) {
  return (
    <div className={'full-page-background-image-container' + (props.classes ? ' ' + props.classes : '')}>
      {props.scrimColor ? <Scrim backgroundColor={props.scrimColor} height='100%' width='100%' /> : ''}
      <Image src={props.src} altText={props.altText} classes={'full-page-background-image'} />
    </div>
  );
};