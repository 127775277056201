import React from 'react';
import "./emailForm.css";

const ContactGoogleForm: React.FC = () => {
  return (
    <div className="email-form-container">
      <iframe title="Grow Your Muscles Interest Form" src="https://docs.google.com/forms/d/e/1FAIpQLSck8IIUS4FXl1ommjQFdPrhwzkzth6Uq9u0dSI3fS4qw08L_A/viewform?embedded=true" width="100%" height="1205" frameBorder={0} marginHeight={0} marginWidth={0}>Loading…</iframe>
    </div>
  );
};

export default ContactGoogleForm;
