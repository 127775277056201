import React from 'react';
import "./footer.css";
import { FacebookButton, InstagramButton, MailButton, LogoButton, ScheduleButton } from '../button/button';

export function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <InstagramButton />
        <FacebookButton />
        {/* <YouTubeButton /> */}
        <LogoButton />
        <ScheduleButton />
        <MailButton />
        {/* <PhoneButton /> */}
      </div >
    </div >
  );
};