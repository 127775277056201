import { TimeSlotData } from "./timeSlot";

const COMPANY_EMAIL = 'grow.your.muscles.training@gmail.com'

export function createEmailClickHref() {
  const recipient = COMPANY_EMAIL;
  const subject = '[GrowYourMuscles Website Question]';
  const body = 'Hello GrowYourMuscles,';

  return `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
};

export function getEmail() {
  return COMPANY_EMAIL;
}

export function createEmailWithRequestedDayAndTime(dayOfWeek: string, timeSlot: TimeSlotData) {
  // Navigate to /contact page with URL parameters
  const url = new URL('/contact', window.location.origin);
  url.searchParams.set('dayOfWeek', dayOfWeek);
  url.searchParams.set('startTime', timeSlot.startTime);
  url.searchParams.set('endTime', timeSlot.endTime);
  window.location.href = url.toString();
}

export function createEmailWithFreeSessionRequest() {
  // Navigate to /contact page with URL parameters
  const url = new URL('/contact', window.location.origin);
  url.searchParams.set('freeTrial', 'true');
  window.location.href = url.toString();
}